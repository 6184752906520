<template>
  <div>
    <the-breadcrumbs :custom-breadcrumbs="breadcrumbs"></the-breadcrumbs>
    <folder-details
      :items="item.children"
      :loading="loading"
      :page-title="item.name"
      :item-id="item.id"
    ></folder-details>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import FolderDetails from "@/components/FolderDetails"
import GuideItemsRepository from "@/repositories/guide_items_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Ordner"
  },
  components: {
    TheBreadcrumbs,
    FolderDetails
  },
  mixins: [ApiErrorHandlerMixin],
  beforeRouteUpdate(to, from, next) {
    this.loadItem(to.params.id)
    next()
  },
  data() {
    return {
      loading: false,
      item: {
        name: "",
        children: [],
        parent: {}
      }
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {
          path: "/guide",
          text: "Wissenswertes"
        }
      ].concat(this.getItemParents(this.item).reverse())
    }
  },
  async created() {
    this.loadItem(this.$route.params.id)
  },
  methods: {
    async loadItem(id) {
      this.loading = true

      try {
        this.item = await GuideItemsRepository.get(id)
        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleApiError(error)
      }
    },
    getItemParents(item) {
      let parents = []
      if (item.parent) {
        parents.push({
          path: `/guide_items/${item.parent.id}`,
          text: item.parent.name
        })
        if (item.parent.parent) {
          parents = parents.concat(this.getItemParents(item.parent))
        }
      }
      return parents
    }
  }
}
</script>
